/* You can add global styles to this file, and also import other style files */

/* Importing Bootstrap SCSS file. */
@import '../node_modules/bootstrap/dist/css/bootstrap.css';
@import '../node_modules//bootstrap-icons/font/bootstrap-icons.scss';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700;900&display=swap');
@import '~@angular/cdk/overlay-prebuilt.css';
@import "~font-awesome/css/font-awesome.css";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    position: relative;
    font-family: 'Roboto', sans-serif;
    background: #f4f7fa;
}

.card {
    display: flex;
    flex-direction: column;
    border-radius: .5rem;
    background-color: #fff;
    margin: 1.25rem 0 1.25rem 0;
    box-shadow: 0 .25rem .5rem #e6edef;
    color: #495057;

    .card-head {
        padding: 1rem 1.5rem;
        border-bottom: 1px solid #edf1f7;
        font-size: 1rem;
        font-weight: 600;
    }

    .card-body {
        padding: 1rem 1.5rem;
    }
}
